import React from 'react';
import { useTranslation } from 'react-i18next';
import { Company, ArrowRight, Glasses } from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import './styles.less';

const BASE = 'pages.private.KYBCompany.pages.companyDetails.subPages.people';

const RelationshipArrow = () => {
  return (
    <div className="kyb-relationship-list-arrow">
      <ArrowRight className="kyb-relationship-list-icon" />
    </div>
  );
};

const RelationshipType = ({ entityType }) => {
  if (entityType === 'company') {
    return (
      <div className="kyb-relationship-list-type">
        <Company className="kyb-relationship-list-icon" />
      </div>
    );
  }
  if (entityType === 'person') {
    return (
      <div className="kyb-relationship-list-type">
        <Person className="kyb-relationship-list-icon" />;
      </div>
    );
  }
  if (entityType === 'intellectual_property') {
    return (
      <div className="kyb-relationship-list-type">
        <Glasses className="kyb-relationship-list-icon" />
      </div>
    );
  }
  return null;
};

const RelationshipField = ({ field }) => {
  const { t } = useTranslation('kyb-business-identity-relationship');

  if (!field) {
    return null;
  }

  return (
    <>
      <RelationshipArrow />
      <div className="kyb-relationship-list-field">{t(field)}</div>
    </>
  );
};

const RelationshipPath = ({ payload }) => {
  const entity = payload?.entity;

  return (
    <div className="kyb-relationship-list-path">
      <RelationshipField field={payload.field} />
      <RelationshipArrow />
      <RelationshipType entityType={entity.entityType} />
      <div className="kyb-relationship-list-info">{entity?.name}</div>
    </div>
  );
};

const RelationshipListItem = ({ payload, entityType }) => {
  return (
    <div className="kyb-relationship-list-item">
      <div className="kyb-relationship-list-inner">
        <RelationshipType entityType={entityType || 'company'} />
        {payload?.path?.map((item, index) => (
          <RelationshipPath key={item.entity.id} payload={item} index={index} />
        ))}
      </div>
    </div>
  );
};

const RelationshipList = ({ payload, entityType }) => {
  return (
    <div className="kyb-relationship-list">
      {payload.data.map((item) => (
        <RelationshipListItem key={item.source} payload={item} entityType={entityType} />
      ))}
    </div>
  );
};

export default RelationshipList;
