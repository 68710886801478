/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCompanySectionsMapping({
  execution,
  kybCreditReport,
  kybBusinessIdentity,
}) {
  const { t: translate } = useTranslation();

  const companyLinksStatistic = useMemo(() => {
    const countries = new Set();
    kybCreditReport?.groupStructure
      ?.filter((company) => company?.country)
      ?.forEach((company) => countries.add(company.country));

    const countCompanies = kybCreditReport?.groupStructure?.length || 0;
    const countContries = countries.size;

    return {
      countCompanies,
      countContries,
    };
  }, [kybCreditReport]);

  const ultimateParentCompany = useMemo(
    () => kybCreditReport?.groupStructure?.find((c) => c.relationType.isUltimateParent),
    [kybCreditReport]
  );

  const imediateParentCompany = useMemo(
    () => kybCreditReport?.groupStructure?.find((c) => c.relationType.isImmediateParent),
    [kybCreditReport]
  );

  const allCountries = useMemo(() => {
    const allCountries = new Set();
    allCountries.add(kybCreditReport?.country);
    kybBusinessIdentity?.countries?.forEach((country) => {
      allCountries.add(country);
    });
    allCountries.add(execution?.parameters?.manualCompanyCountryCode);
    return Array.from(allCountries).filter((c) => !!c);
  }, [kybBusinessIdentity, kybCreditReport, execution]);

  const priorityCountry = useMemo(
    () =>
      execution?.parameters?.searchInput?.country || // Trust
      execution?.parameters?.manualCompanyCountryCode || // Onboarding
      allCountries?.[0], // Fallback
    [execution, allCountries]
  );

  const countryName = useMemo(() => {
    const country =
      priorityCountry || kybCreditReport?.country || kybBusinessIdentity?.countries[0];
    return country ? translate(`countries.${country}`, '') : null;
  }, [kybCreditReport, kybBusinessIdentity, translate, priorityCountry]);

  const businessIdentityAddresses = useMemo(() => {
    const addresses = kybBusinessIdentity?.addresses || [];
    const mainAddress = kybBusinessIdentity?.addresses?.[0];

    return {
      mainAddress: mainAddress ?? null,
      otherAddresses: addresses
        .filter((address) => address !== mainAddress)
        ?.map((address) => ({
          fullAddress: address,
        })),
    };
  }, [kybBusinessIdentity]);

  const companyNameTranslated = useMemo(() => {
    const allCompanyNames = [];

    kybBusinessIdentity?.attributes?.forEach((v) => {
      if (v?.name === 'name') {
        allCompanyNames.push(...v?.data);
      }
    });

    const aliasNames = allCompanyNames?.filter(
      (name) =>
        name?.properties?.context === 'alias' &&
        name?.properties?.language === 'en' &&
        name?.properties?.value
    );

    const translatedByGoogleNames = allCompanyNames?.filter(
      (name) =>
        name?.properties?.context === 'primary' &&
        name?.properties?.value === kybBusinessIdentity?.name &&
        name?.properties?.translated
    );

    const translatedName = kybBusinessIdentity?.translatedName;

    return aliasNames.length || translatedByGoogleNames.length || translatedName
      ? ` - ${
          translatedByGoogleNames[0]?.properties?.translated ||
          translatedName ||
          aliasNames[0]?.properties?.value
        }`
      : '';
  }, [kybBusinessIdentity]);

  const companyName = useMemo(() => {
    const name =
      kybCreditReport?.name ??
      kybBusinessIdentity?.name ??
      execution?.parameters?.manualCompanyName;

    return name + companyNameTranslated;
  }, [companyNameTranslated, execution, kybBusinessIdentity, kybCreditReport]);

  const companyInfo = useMemo(() => {
    const tradeDebtors =
      kybCreditReport?.localFinancialStatements?.[0]?.balanceSheet?.tradeDebtors;
    const tradeCreditors =
      kybCreditReport?.localFinancialStatements?.[0]?.balanceSheet?.tradeCreditors;
    const address = kybBusinessIdentity?.addresses?.[0];
    const mainAddress =
      kybCreditReport?.contact?.addresses?.find((a) => a?.main)?.fullAddress ??
      kybCreditReport?.contact?.addresses?.at(0)?.fullAddress ??
      businessIdentityAddresses?.mainAddress;

    return {
      companyName,
      creditReport: {
        name: kybCreditReport?.name ?? null,
        priorityCountry,
        country: kybCreditReport?.country ?? null,
        number: kybCreditReport?.number,
        status:
          kybCreditReport?.status?.description || kybCreditReport?.status?.code || null,
        registrationDate: kybCreditReport?.registrationDate ?? null,
        companyType: kybCreditReport?.legalForm ?? null,
        vatNumber: kybCreditReport?.vatRegistrationNumber ?? null,
        sic07Code:
          kybCreditReport?.activities?.find((c) => c?.classification === 'SIC07')?.code ||
          null,
        sic07Description:
          kybCreditReport?.activities?.find((c) => c?.classification === 'SIC07')
            ?.description || null,
        telephone:
          kybCreditReport?.contact?.phoneNumber ??
          kybBusinessIdentity?.contact?.phoneNumbers?.[0] ??
          null,
        email:
          kybCreditReport?.contact?.emails?.[0] ??
          kybBusinessIdentity?.contact?.emails?.[0] ??
          null,
        url:
          kybCreditReport?.contact?.websites?.[0] ??
          kybBusinessIdentity?.contact?.websites?.[0] ??
          null,
        address: mainAddress || null,
        otherAddresses:
          kybCreditReport?.contact?.addresses?.filter((a) => !a?.main) ||
          businessIdentityAddresses?.otherAddresses ||
          [],
        ftseIndex:
          kybCreditReport?.additionalInformation?.miscellaneous?.ftseIndex ?? null,
        shareCapitalValue:
          kybCreditReport?.shareCapitalStructure?.issuedShareCapital?.value ?? 0,
        shareCapitalCurrency:
          kybCreditReport?.shareCapitalStructure?.issuedShareCapital?.currency,
        charges: kybCreditReport?.additionalInformation?.mortgageDetails?.length ?? 0,
        landRegistry:
          kybCreditReport?.additionalInformation?.landRegistry
            ?.registeredLandAndProperty ?? null,
        mainActivity:
          kybCreditReport?.activities?.find((a) => a?.main)?.description ?? null,
        filingDateOfAccounts:
          kybCreditReport?.additionalInformation?.miscellaneous?.filingDateOfAccounts ??
          null,
        charityNumber:
          kybCreditReport?.additionalInformation?.miscellaneous?.charityNumber ?? null,
        accountant:
          kybCreditReport?.otherInformation?.advisors?.[0]?.accountantName ?? null,
        tradeDebtors:
          typeof tradeDebtors === 'undefined' ? null : tradeDebtors ? 'Yes' : 'No',
        tradeCreditors:
          typeof tradeCreditors === 'undefined' ? null : tradeCreditors ? 'Yes' : 'No',
      },
      businessIdentity: {
        countries: kybBusinessIdentity?.countries ?? [],
        priorityCountry,
        name: kybBusinessIdentity?.name ?? null,
        identifier: kybBusinessIdentity?.identifiers?.[0]?.value,
        businessPurpose: kybBusinessIdentity?.businessPurpose ?? null,
        address: address || null,
        names:
          kybBusinessIdentity?.attributes
            ?.find((a) => a.name === 'name')
            ?.data?.filter((d) => d?.properties?.context === 'alias')
            ?.map((d) => d?.properties?.value ?? null) || [],
        sources: kybBusinessIdentity?.sources || [],
        status:
          kybBusinessIdentity?.status?.description ||
          kybBusinessIdentity?.status?.code ||
          null,
        registrationIncorporationDate:
          kybBusinessIdentity?.attributes
            ?.find((a) => a?.name === 'status')
            ?.data?.find((d) => d?.properties?.value === 'incorporated')?.properties
            ?.date ?? null,
        companyType:
          kybBusinessIdentity?.attributes?.find((a) => a?.name === 'company_type')
            ?.data?.[0]?.properties?.value || null,
        ownersShareholders:
          kybBusinessIdentity?.relationships?.shareHolders?.map((s) => s?.name ?? null) ||
          [],
        risks: kybBusinessIdentity?.riskData ?? [],
        attributes: kybBusinessIdentity?.attributes ?? [],
      },
    };
  }, [
    kybCreditReport,
    kybBusinessIdentity,
    businessIdentityAddresses,
    companyName,
    priorityCountry,
  ]);

  const companyLinks = useMemo(() => {
    return {
      countCompanies: companyLinksStatistic.countCompanies,
      countCountries: companyLinksStatistic.countContries,
      holdingCompanyName: imediateParentCompany?.name || null,
      ownershipStatus: imediateParentCompany?.status || null,
      ultimateHoldingCompanyName: ultimateParentCompany?.name || null,
      groupStructure: kybCreditReport?.groupStructure?.filter((c) => c?.name) || [],
    };
  }, [
    companyLinksStatistic,
    ultimateParentCompany,
    imediateParentCompany,
    kybCreditReport,
  ]);

  const financial = useMemo(() => {
    return {
      companyName,
      localFinancialStatements: kybCreditReport?.localFinancialStatements ?? [],
      financialStatements: kybCreditReport?.financialStatements ?? [],
      negativeInformation: kybCreditReport?.negativeInformation ?? {},
    };
  }, [kybCreditReport, companyName]);

  const people = useMemo(
    () => ({
      creditReport: {
        currentDirectors: kybCreditReport?.directors?.filter((d) => d?.isCurrent) ?? [],
        previousDirectors: kybCreditReport?.directors?.filter((d) => !d?.isCurrent) ?? [],
        shareHolders: kybCreditReport?.shareCapitalStructure?.shareHolders ?? [],
        personsWithSignificantControl:
          kybCreditReport?.additionalInformation?.personsWithSignificantControl,
      },
      businessIdentity: {
        businessIdentityId: execution?.parameters?.services?.businessIdentityId,
        entityType: kybBusinessIdentity?.entityType,
        directors: kybBusinessIdentity?.relationships?.directors ?? [],
        officers: kybBusinessIdentity?.relationships?.officers ?? [],
        shareHolders: kybBusinessIdentity?.relationships?.shareHolders ?? [],
        registeredAgents: kybBusinessIdentity?.relationships?.registeredAgents ?? [],
      },
    }),
    [kybCreditReport, kybBusinessIdentity]
  );
  const comments = useMemo(() => kybCreditReport?.additionalInformation?.commentaries, [
    kybCreditReport,
  ]);
  const records = useMemo(
    () => ({
      companyName,
      count: kybBusinessIdentity?.records?.count || 0,
      records: kybBusinessIdentity?.records?.data || [],
      sources: kybBusinessIdentity?.sources || [],
    }),
    [companyName, kybBusinessIdentity]
  );

  const input = {
    parameters: execution?.parameters || {},
    metadata: execution?.metadata || {},
  };

  const summary = useMemo(() => {
    return {
      title: companyName || execution?.parameters?.manualCompanyName,
      allCountries,
      priorityCountry,
      countryName,
      comments: kybCreditReport?.additionalInformation?.commentaries ?? [],
      identifier:
        companyInfo.creditReport.number ??
        companyInfo.businessIdentity.identifier ??
        execution?.parameters?.manualCompanyNumber,
      number:
        kybCreditReport?.number ||
        kybBusinessIdentity?.number ||
        execution?.parameters?.manualCompanyNumber,
      taxId: companyInfo.creditReport.vatNumber,
      status: companyInfo.creditReport.status || companyInfo.businessIdentity.status,
      statusCode:
        execution?.data?.company?.status ||
        kybCreditReport?.status?.code ||
        kybBusinessIdentity?.status?.code ||
        '',
      registrationDate:
        companyInfo.creditReport.registrationDate ||
        companyInfo.businessIdentity.registrationIncorporationDate,
      telephone: companyInfo.creditReport.telephone,
      email: companyInfo.creditReport.email,
      url: companyInfo.creditReport.url,
      address: companyInfo.creditReport.address,
      otherAddresses: companyInfo.creditReport.otherAddresses,
      directorsLength:
        kybCreditReport?.directors?.filter((d) => d?.isCurrent)?.length ??
        kybBusinessIdentity?.relationships?.directors?.length ??
        0,
      directors:
        kybCreditReport?.directors?.filter((d) => d?.isCurrent) ??
        kybBusinessIdentity?.relationships?.directors ??
        [],
      UBOAndPSC:
        kybCreditReport?.additionalInformation?.personsWithSignificantControl?.active ??
        [],
      UBOAndPSCLength:
        kybCreditReport?.additionalInformation?.personsWithSignificantControl?.active
          ?.length ?? 0,
      shareHoldersLength:
        kybCreditReport?.shareCapitalStructure?.shareHolders?.length ??
        kybBusinessIdentity?.relationships?.shareHolders?.length ??
        0,
      shareHolders:
        kybCreditReport?.shareCapitalStructure?.shareHolders ??
        kybBusinessIdentity?.relationships?.shareHolders ??
        [],
      internationalScore: kybCreditReport?.creditRating?.internationalScore ?? null,
      creditLimitValue: kybCreditReport?.creditRating?.creditLimit?.value ?? 0,
      creditLimitCurrency: kybCreditReport?.creditRating?.creditLimit?.currency,
      ultimateParentName: ultimateParentCompany?.name,
      ultimateParentCountry: ultimateParentCompany?.country,
      ultimateParentNumber: ultimateParentCompany?.number ?? null,
      ultimateParentLatestKeyFinancial: ultimateParentCompany?.latestAnnualAccounts,
      companyLinksCountCompanies: companyLinksStatistic.countCompanies,
      companyLinksCountCountries: companyLinksStatistic.countContries,
      keyFinancials: {
        turnover:
          kybCreditReport?.localFinancialStatements?.filter(
            (s) => s?.profitAndLoss?.turnover && s.yearEndDate
          ) ?? [],
        preTaxProfit:
          kybCreditReport?.localFinancialStatements?.filter(
            (s) => s?.profitAndLoss?.preTaxProfit && s.yearEndDate
          ) ?? [],
        shareholdersFunds:
          kybCreditReport?.localFinancialStatements?.filter(
            (s) => s?.balanceSheet?.totalShareholdersEquity && s.yearEndDate
          ) ?? [],
        numberOfEmployees:
          kybCreditReport?.otherInformation?.employeesInformation?.filter(
            (e) => e?.numberOfEmployees && e.year
          ) ?? [],
      },
    };
  }, [
    kybCreditReport,
    kybBusinessIdentity,
    companyInfo,
    companyLinksStatistic,
    ultimateParentCompany,
    countryName,
    companyName,
    priorityCountry,
    allCountries,
    execution,
  ]);

  const riskFactors = kybBusinessIdentity?.riskData ?? [];

  return {
    summary,
    companyInfo,
    financial,
    riskFactors,
    people,
    companyLinks,
    records,
    comments,
    input,
    workflowId: execution?.workflowId,
    workflowName: execution?.workflow?.definition?.name,
  };
}
