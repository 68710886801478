import React from 'react';
import { Modal, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Close } from '@combateafraude/icons/general';
import './styles.less';

const { Title, Text } = Typography;

const I18N_BASE_PATH = 'moreRelationshipsModal';

const MoreRelationshipModal = ({ visible, onClose, onAccept }) => {
  const { t: translate, ready } = useTranslation('kyb-more-relationships');

  if (!ready) {
    return null;
  }

  const closeModal = () => {
    onClose();
  };

  const acceptModal = () => {
    onAccept();
  };

  return (
    <Modal
      closable
      closeIcon={<Close />}
      footer={null}
      width="459px"
      centered
      visible={visible}
      onCancel={closeModal}
    >
      <div className="caf-kyb-connection-modal">
        <Title className="caf-kyb-connection-modal-title">
          {translate(`${I18N_BASE_PATH}.title`)}
        </Title>
        <Text className="caf-kyb-connection-modal-text">
          {translate(`${I18N_BASE_PATH}.text`)}
        </Text>
        <div className="caf-kyb-connection-modal-buttons">
          <Button className="caf-kyb-connection-modal-cancel-button" onClick={closeModal}>
            {translate(`${I18N_BASE_PATH}.cancelButton`)}
          </Button>
          <Button className="caf-kyb-connection-modal-ok-button" onClick={acceptModal}>
            {translate(`${I18N_BASE_PATH}.okButton`)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MoreRelationshipModal;
