import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Row, Typography } from 'antd';
import Button from 'components/Button';
import { CheckCircle2 } from '@combateafraude/icons/general';

import './styles.less';

const { Title, Text } = Typography;

const I18N_BASE_PATH =
  'pages.private.globalProfiles.components.sugestion.connectionModal';

const NotConnectModal = ({ closeModal, setNextStep, nextStep }) => {
  const { t: translate } = useTranslation();

  return !nextStep ? (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="title-connect-modal">
            {translate(`${I18N_BASE_PATH}.notConnect`)}
          </Title>
        </Row>
        <Row align="middle" justify="center">
          <Text className="text-connect-modal">
            {translate(`${I18N_BASE_PATH}.notConnect.confimation`)}
          </Text>
        </Row>

        <div className="button-container-connect-modal">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttonCancel`)}
          </Button>
          <Button onClick={() => setNextStep(true)} className="not-connect-ok-button">
            {translate(`${I18N_BASE_PATH}.buttonOk`)}
          </Button>
        </div>
      </section>
    </div>
  ) : (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="final-text-not-connect">
            {translate(`${I18N_BASE_PATH}.notConnect.finalMessage`)}
          </Title>
        </Row>
      </section>
    </div>
  );
};

const ConnectModal = ({ closeModal, setNextStep, nextStep }) => {
  const { t: translate } = useTranslation();

  return !nextStep ? (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="title-connect-modal">
            {translate(`${I18N_BASE_PATH}.connect`)}
          </Title>
        </Row>
        <Row align="middle" justify="center">
          <Text className="text-connect-modal">
            {translate(`${I18N_BASE_PATH}.connect.confimation`)}
          </Text>
        </Row>

        <div className="button-container-connect-modal">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttonCancel`)}
          </Button>
          <Button onClick={() => setNextStep(true)} className="not-connect-ok-button">
            {translate(`${I18N_BASE_PATH}.buttonOk`)}
          </Button>
        </div>
      </section>
    </div>
  ) : (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <CheckCircle2
            className="icon"
            style={{
              width: '74px',
              height: '74px',
              color: '#004AF7',
              marginTop: '26px',
              marginBottom: '22px',
            }}
          />
          <Title className="final-text-connect">
            {translate(`${I18N_BASE_PATH}.connect.finalMessage`)}
          </Title>
        </Row>
      </section>
    </div>
  );
};

const ConnectionModal = ({ buttonType = '', visible, onClose }) => {
  const [nextStep, setNextStep] = useState(false);

  const closeModal = () => {
    onClose();
    setNextStep(false);
  };

  return (
    <Modal
      closable
      closeIcon={<i className="caf-ic_close font-size-18" />}
      footer={null}
      width="538px"
      centered
      visible={visible}
      onCancel={closeModal}
    >
      {buttonType !== 'connect' ? (
        <NotConnectModal
          closeModal={closeModal}
          setNextStep={setNextStep}
          nextStep={nextStep}
        />
      ) : (
        <ConnectModal
          closeModal={closeModal}
          setNextStep={setNextStep}
          nextStep={nextStep}
        />
      )}
    </Modal>
  );
};

export default ConnectionModal;
