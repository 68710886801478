export const RELATIONSHIPS = [
  'director_of',
  'has_director',
  'secretary_of',
  'has_secretary',
  'lawyer_of',
  'has_lawyer',
  'notify_party_of',
  'has_notify_party',
  'judicial_representative_of',
  'has_judicial_representative',
  'owner_of',
  'has_owner',
  'legal_successor_of',
  'has_legal_successor',
  'lawyer_in',
  'executive_of',
  'has_executive',
  'registered_agent_of',
  'has_registered_agent',
  'partner_of',
  'has_partner',
  'beneficial_owner_of',
  'has_beneficial_owner',
  'auditor_of',
  'has_auditor',
  'client_of',
  'has_client',
  'member_of_the_board_of',
  'has_member_of_the_board',
  'issuer_of',
  'has_issuer',
  'supervisor_of',
  'has_supervisor',
  'shipper_of',
  'shipped_by',
  'receiver_of',
  'received_by',
  'founder_of',
  'has_founder',
  'associate_of',
  'has_associate',
  'employee_of',
  'has_employee',
  'legal_representative_of',
  'has_legal_representative',
  'branch_of',
  'has_branch',
  'ships_to',
  'receives_from',
  'shareholder_of',
  'has_shareholder',
  'sole_proprietor_of',
  'has_sole_proprietor',
  'carrier_of',
  'has_carrier',
  'party_to',
  'has_party',
  'liquidator_of',
  'has_liquidator',
  'subsidiary_of',
  'has_subsidiary',
  'family_of',
  'linked_to',
  'legal_predecessor_of',
  'has_legal_predecessor',
  'officer_of',
  'has_officer',
  'manager_of',
  'has_manager',
];

export const ENTITY_TYPES = [
  'unknown',
  'company',
  'person',
  'vessel',
  'shipment',
  'intellectual_property',
  'security',
  'generic',
  'legal_matter',
  'tradename',
  'property',
  'aircraft',
];

export const RISK_FACTORS = [
  'eu_high_risk_third',
  'reputational_risk_modern_slavery',
  'state_owned',
  'formerly_sanctioned',
  'reputational_risk_terrorism',
  'reputational_risk_organized_crime',
  'reputational_risk_financial_crime',
  'reputational_risk_bribery_and_corruption',
  'reputational_risk_other',
  'reputational_risk_cybercrime',
  'regulatory_action',
  'law_enforcement_action',
  'xinjiang_geospatial',
];
