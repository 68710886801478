import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardWrapper } from 'components/CardWrapper';
import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useWorkflow } from 'hooks/useWorkflow';
import AlertNewness from 'components/AlertNewness';
import ExecutionCardStatus from '../CompanyDetails/components/ExecutionCardStatus';
import CompanyInfo from './components/TabsComponent/CompanyInfo';
import Wrapper from '../../wrapper';
import ModalAddresses from '../CompanyDetails/components/TabsComponent/Summary/ModalAddresses';
import People from '../CompanyDetails/components/TabsComponent/People';
import FinancialInfo from '../CompanyDetails/components/TabsComponent/FinancialInfo';
import Files from '../CompanyDetails/components/TabsComponent/Files';
import Records from './components/TabsComponent/Records';
import { Header } from './components/Header';
import CustomFetchLoader from '../CompanyDetails/components/CustomFetchLoader';
import useCompanySectionsMapping from '../CompanyDetails/hooks/useCompanySectionsMapping';
import InputData from '../CompanyDetails/components/TabsComponent/InputData';
import Summary from './components/TabsComponent/Summary/Summary';
import Comments from './components/TabsComponent/Comments';
import RiskFactors from './components/TabsComponent/RiskFactors';
import GroupStructure from './components/TabsComponent/GroupStructure';
import './styles.less';

const { TabPane } = Tabs;

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails';

const KYBCompanyDetails = () => {
  const { t: translate } = useTranslation();
  const { transactionId } = useParams();
  const { user } = useAuth();
  const {
    loadExecution: getExecution,
    loadingExecution,
    execution,
    reloadExecution,
    refreshExecution,
  } = useExecution();

  const { getWorkflowsData, loading: loadingWorkflows } = useWorkflow();

  const {
    sections: {
      kybCreditReport: creditReport = {},
      kybBusinessIdentity: businessIdentity = {},
    } = {},
  } = execution ?? {};

  const {
    summary: summaryData,
    companyInfo: companyInfoData,
    financial: financialData,
    riskFactors: riskFactorsData,
    people: peopleData,
    companyLinks: companyLinksData,
    records: recordsData,
    comments: commentsData,
    input: inputData,
    workflowName: workflowNameData,
    workflowId: workflowIdData,
  } = useCompanySectionsMapping({
    execution,
    kybCreditReport: creditReport?.data,
    kybBusinessIdentity: businessIdentity?.data,
  });

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    subTab: StringParam,
    origin: StringParam,
  });

  const getExecutionParams = {
    executionId: transactionId,
    clearData: true,
    queryParams: {
      includeChildren: 'true',
    },
  };

  useEffect(() => {
    if (!getExecution) return;
    if (query.origin !== 'search') getExecution(getExecutionParams);
    getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
  }, [transactionId]); // eslint-disable-line

  useEffect(() => {
    if (reloadExecution) getExecution(getExecutionParams);
  }, [reloadExecution]); // eslint-disable-line

  const executionStatus = useMemo(() => execution?.status || 'PROCESSING', [execution]);

  const {
    Modal: AddressesModal,
    modalVisible: addressesModalVisible,
    openModal: openAddressModal,
  } = ModalAddresses();

  // When changing tabs was re-render this div, restarting the countdown, useMemo solve the problem
  const rightSide = useMemo(() => {
    return (
      <aside className="kyb-company-details-right-side">
        <CardWrapper hasPadding className="remove-bottom-top height-100">
          <ExecutionCardStatus type={executionStatus} />
          <div className="alert-improvements">
            <AlertNewness />
          </div>
        </CardWrapper>
      </aside>
    );
  }, [executionStatus]);

  const companyInfoEmpty = Boolean(!creditReport?.data && !businessIdentity?.data);

  const peopleTabDisabled = useMemo(() => {
    return (
      !summaryData?.directorsLength &&
      !summaryData?.UBOandPSCLength &&
      !summaryData?.shareHoldersLength
    );
  }, [summaryData]);

  const groupStructureTabDisabled = useMemo(() => {
    return !summaryData?.companyLinksCountCompanies && !summaryData?.ultimateParentName;
  }, [summaryData]);

  const peopleCount = [
    peopleData.creditReport.currentDirectors?.length,
    peopleData.creditReport.previousDirectors?.length,
    peopleData.creditReport.shareHolders?.length,
    Object.keys(peopleData.creditReport.personsWithSignificantControl || {})?.length,
    peopleData.businessIdentity.directors?.length,
    peopleData.businessIdentity.officers?.length,
    peopleData.businessIdentity.shareHolders?.length,
  ].some((value) => value);

  const financialCount = [
    financialData.financialStatements?.length &&
      financialData.localFinancialStatements?.length &&
      Object.keys(financialData.negativeInformation || {})?.length,
    summaryData.internationalScore ? 1 : 0,
  ].some((value) => value);

  const summaryDisabledMap = {
    summary: companyInfoEmpty,
    companyInfo: companyInfoEmpty,
    financialRisk: !financialCount,
    comments: !commentsData?.length,
    riskFactors: !riskFactorsData?.length,
    people: !peopleCount,
    groupStructure: groupStructureTabDisabled,
    records: !recordsData.count,
  };

  return (
    <>
      {addressesModalVisible ? AddressesModal : <></>}
      <Wrapper id="KYB-company-details-wrapper">
        {loadingExecution ||
        loadingWorkflows ||
        !execution ||
        execution?.status === 'PROCESSING' ? (
          <>
            <CustomFetchLoader
              refreshExecution={refreshExecution}
              message={translate(
                `${I18N_BASE_PATH}.loadingMessage`,
                'The report is being collated, please wait a moment...'
              )}
            />
          </>
        ) : (
          <div>
            <CardWrapper
              hasPadding
              className="remove-bottom-top mrg-btm-15"
              style={{ minHeight: 0 }}
            >
              <Header
                createdAt={execution?.createdAt}
                updatedAt={execution?.updatedAt}
                transactionChildrenIds={execution?.children || []}
                companyData={{
                  transactionId,
                  executionStatus,
                  summary: summaryData,
                  records: recordsData,
                  companyLinks: companyLinksData,
                  people: peopleData,
                  riskFactors: riskFactorsData,
                  comments: commentsData,
                  financial: financialData,
                  companyInfo: companyInfoData,
                  inputData,
                  workflowId: workflowIdData,
                  workflowName: workflowNameData,
                  summaryDisabledMap,
                }}
              />
            </CardWrapper>
            <section className="kyb-company-details-wrapper">
              <aside className="kyb-company-details-left-side">
                <CardWrapper hasPadding className="remove-bottom-top">
                  <Tabs
                    className="company-details-tabs-wrapper"
                    defaultActiveKey="summary"
                    activeKey={query.tab}
                    onChange={(tab) => setQuery({ tab, subTab: undefined })}
                    destroyInactiveTabPane
                  >
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.summary`, 'Summary')}
                      key="summary"
                    >
                      <Summary
                        summary={summaryData}
                        records={recordsData}
                        companyInfo={companyInfoData}
                        disabled={summaryDisabledMap}
                      />
                    </TabPane>
                    <TabPane
                      tab={translate(
                        `${I18N_BASE_PATH}.tabs.companyInfo`,
                        'Company info'
                      )}
                      key="companyInfo"
                      disabled={companyInfoEmpty}
                    >
                      <CompanyInfo payload={companyInfoData} />
                    </TabPane>
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.financial`, 'Financial')}
                      key="financial"
                      disabled={!financialCount}
                    >
                      <FinancialInfo financialData={financialData} />
                    </TabPane>
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.comments`, 'Comments')}
                      key="comments"
                      disabled={!commentsData?.length}
                    >
                      <Comments comments={commentsData} />
                    </TabPane>
                    <TabPane
                      tab={translate(
                        `${I18N_BASE_PATH}.tabs.riskFactors`,
                        'Risk factors'
                      )}
                      key="riskFactors"
                      disabled={!riskFactorsData?.length}
                    >
                      <RiskFactors payload={riskFactorsData} />
                    </TabPane>
                    <TabPane
                      tab={translate(
                        `${I18N_BASE_PATH}.tabs.relationships`,
                        'Relationships'
                      )}
                      key="relationships"
                      disabled={peopleTabDisabled}
                    >
                      <People
                        query={query}
                        setQuery={setQuery}
                        peopleData={peopleData}
                        summaryData={summaryData}
                      />
                    </TabPane>
                    <TabPane
                      tab={translate(
                        `${I18N_BASE_PATH}.tabs.groupStructure`,
                        'Group structure'
                      )}
                      key="groupStructure"
                      disabled={groupStructureTabDisabled}
                    >
                      <GroupStructure payload={companyLinksData} />
                    </TabPane>
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.records`, 'Records')}
                      key="records"
                      disabled={!recordsData?.count}
                    >
                      <Records transactionId={transactionId} data={recordsData} />
                    </TabPane>
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.files`, 'Files')}
                      key="files"
                    >
                      <Files />
                    </TabPane>
                    <TabPane
                      tab={translate(`${I18N_BASE_PATH}.tabs.inputData`, `Input Data`)}
                      key="inputData"
                    >
                      <InputData inputData={inputData} />
                    </TabPane>
                  </Tabs>
                </CardWrapper>
              </aside>
              {rightSide}
            </section>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default KYBCompanyDetails;
