import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import Loading from 'components/Loading';
import './styles.less';
import { Filter } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';
import RelationshipList from '../RelationshipList';
import FormComponent from './MoreRelationshipComponents/Form';

const { Text } = Typography;

const MoreRelationshipsContent = ({ businessIdentityId, entityType }) => {
  const { t: translate, ready } = useTranslation('kyb-more-relationships');
  const [formIsVisible, setFormIsVisible] = useState(false);

  const [apiResponse, setApiResponse] = useState(null);
  const { post: postMethod, loading: fetchLoading } = useFetch();

  const loading = fetchLoading || !ready;

  useEffect(() => {
    async function fetchData() {
      if (!businessIdentityId) return [];
      const response = await postMethod({
        url: '/company-explore',
        payload: {
          parameters: {
            businessIdentityId,
            filters: {
              limit: 50,
            },
          },
        },
      });

      setApiResponse(response);
    }

    fetchData();
  }, [businessIdentityId, postMethod]);

  const handleFormSubmit = async (formValues) => {
    // submit form v1
    // const response = await postMethod({
    //   url: '/company-explore',
    //   payload: {
    //     parameters: {
    //       businessIdentityId,
    //       filters: {
    //         limit: 50,
    //         offset: 10,
    //         min_depth: [formValues.levels[0]],
    //         max_depth: [formValues.formValues.levels[1]],
    //         relationships: [formValues.relationship],
    //         countries: [formValues.country],
    //         types: [formValues.entity],
    //       },
    //     },
    //   },
    // });

    // setApiResponse(response);

    console.log({ formValues });
  };

  return (
    <div className="kyb-more-relationship-wrapper">
      <div className="kyb-more-relationship-container">
        <div className="kyb-more-relationship-header-text">
          <Text>{translate(`moreRelationships.headerText`)}</Text>
        </div>
        <div className="kyb-more-relationship-filter-button-container">
          <Button
            type="primary"
            shape="circle"
            icon={<Filter />}
            onClick={() => setFormIsVisible(!formIsVisible)}
            className="kyb-more-relationship-filter-button"
          />
        </div>
      </div>
      {loading && <Loading />}
      <FormComponent visible={formIsVisible} onSubmit={handleFormSubmit} />
      {!loading && apiResponse?.data && (
        <RelationshipList payload={apiResponse.data} entityType={entityType} />
      )}
    </div>
  );
};

export default MoreRelationshipsContent;
