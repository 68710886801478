import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Slider, Checkbox, Typography, Select, Button } from 'antd';
import './styles.less';
import { RELATIONSHIPS, RISK_FACTORS, ENTITY_TYPES } from './constants';

import { countries } from '../../../../../../../../CompanySearching/components/FilterCompanies/countries';

const { Text } = Typography;

const FormComponent = ({ visible, onSubmit }) => {
  const { t: translate, ready } = useTranslation('kyb-more-relationships');

  const [formValues, setFormValues] = useState({
    name: '',
    levels: [20, 50],
    relationship: null,
    country: null,
    entity: null,
    riskfactor: null,
    sharesPercentage: 0,
    excludePossibly: false,
    excludeRelationships: false,
    excludeEntities: false,
  });

  const handleClear = () => {
    setFormValues({
      name: '',
      levels: [20, 50],
      relationship: null,
      country: null,
      entity: null,
      riskfactor: null,
      sharesPercentage: 0,
      excludePossibly: false,
      excludeRelationships: false,
      excludeEntities: false,
    });
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  if (!ready) return null;

  if (!visible) {
    return null;
  }

  return (
    <div className="kyb-more-relationship-form-content">
      <div className="kyb-more-relationship-input-slider-container">
        <div className="kyb-more-relationship-input">
          <Text strong>{translate(`moreRelationships.form.name`)}</Text>
          <Input
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
        </div>
        <div className="kyb-more-relationship-slider">
          <Text strong>{translate(`moreRelationships.form.levels`)}</Text>
          <Slider
            range
            step={10}
            defaultValue={formValues.levels}
            onChange={(value) => setFormValues({ ...formValues, levels: value })}
          />
        </div>
      </div>
      <div className="kyb-more-relationship-selects-group">
        <div className="kyb-more-relationship-selects">
          <Select
            dropdownMatchSelectWidth={400}
            className="kyb-more-relationship-select"
            placeholder={translate(`moreRelationships.form.relationship`)}
            value={formValues.relationship}
            onChange={(value) => setFormValues({ ...formValues, relationship: value })}
            options={
              RELATIONSHIPS.map((value) => ({
                label: translate(`moreRelationships.${value}`),
                value,
              })) || []
            }
          />
          <Select
            dropdownMatchSelectWidth={400}
            className="kyb-more-relationship-select"
            placeholder={translate(`moreRelationships.form.country`)}
            value={formValues.country}
            onChange={(value) => setFormValues({ ...formValues, country: value })}
            options={
              countries.map((country) => ({
                label: translate(`${country.label}`),
                country: country.value,
              })) || []
            }
          />
          <Select
            dropdownMatchSelectWidth={400}
            className="kyb-more-relationship-select"
            placeholder={translate(`moreRelationships.form.entity`)}
            value={formValues.entity}
            onChange={(value) => setFormValues({ ...formValues, entity: value })}
            options={
              ENTITY_TYPES.map((value) => ({
                label: translate(`moreRelationships.${value}`),
                value,
              })) || []
            }
          />
          <Select
            dropdownMatchSelectWidth={400}
            className="kyb-more-relationship-select"
            placeholder={translate(`moreRelationships.form.riskfactor`)}
            value={formValues.riskfactor}
            onChange={(value) => setFormValues({ ...formValues, riskfactor: value })}
            options={
              RISK_FACTORS.map((value) => ({
                label: translate(`moreRelationships.${value}`),
                value,
              })) || []
            }
          />
        </div>
        <div className="kyb-more-relationship-slider">
          <Text strong>{translate(`moreRelationships.form.sharesPercentage`)}</Text>
          <Slider
            step={10}
            value={formValues.sharesPercentage}
            onChange={(value) =>
              setFormValues({ ...formValues, sharesPercentage: value })
            }
          />
        </div>
      </div>
      <div className="kyb-more-relationship-checkboxes">
        <Checkbox
          checked={formValues.excludePossibly}
          onChange={(e) =>
            setFormValues({ ...formValues, excludePossibly: e.target.checked })
          }
        >
          {translate(`moreRelationships.form.excludePossibly`)}
        </Checkbox>
        <Checkbox
          checked={formValues.excludeRelationships}
          onChange={(e) =>
            setFormValues({ ...formValues, excludeRelationships: e.target.checked })
          }
        >
          {translate(`moreRelationships.form.excludeRelationships`)}
        </Checkbox>
        <Checkbox
          checked={formValues.excludeEntities}
          onChange={(e) =>
            setFormValues({ ...formValues, excludeEntities: e.target.checked })
          }
        >
          {translate(`moreRelationships.form.excludeEntities`)}
        </Checkbox>
      </div>
      <div className="kyb-more-relationship-actions">
        <Button type="link" onClick={handleClear}>
          {translate(`moreRelationships.form.clear`)}
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          {translate(`moreRelationships.form.submit`)}
        </Button>
      </div>
    </div>
  );
};

export default FormComponent;
