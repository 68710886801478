import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox, Collapse, Button, Card } from 'antd';
import { Link } from 'react-router-dom';

import { Close } from '@combateafraude/icons/general';
import PageTitle from 'components/PageTitle';
import IconStatus from 'pages/private/Profiles/components/IconStatus';
import { labelColor } from 'utils/handlerStatus';
import _ from 'lodash';

import ValidationsList from '../Tabs/components/Summary/components/LastValidations/components/ValidationsList';

import './styles.less';
import ConnectionModal from './ConnectionModal';

const { Panel } = Collapse;

const suggestions = [
  {
    id: '12345',
    name: 'Bernardo Luis Hamester',
    address: 'Rua dos Andradas 1449',
    docId: '1111111111',
    birthDate: '14/09/1999',
    suggestions: [
      {
        id: '123',
        name: 'Bernardo Luis Hamester',
        address: 'Rua dos Andradas 1449',
        docId: '1111111111',
        birthDate: '14/09/1999',
        status: 'PENDING',
      },
      {
        id: '321',
        name: 'Bernardo Luis Hamester',
        address: 'Rua dos Andradas 1449',
        docId: '1111111111',
        birthDate: '14/09/1999',
        status: 'PENDING',
      },
    ],
  },
  {
    id: '22222',
    name: 'Bernardo Luis Hamester',
    address: 'Rua dos Andradas 1449',
    docId: '1111111111',
    birthDate: '14/09/1999',
    suggestions: [
      {
        id: '333',
        name: 'Bernardo Luis Hamester',
        address: 'Rua dos Andradas 1449',
        docId: '1111111111',
        birthDate: '14/09/1999',
        status: 'PENDING',
      },
      {
        id: '222',
        name: 'Bernardo Luis Hamester',
        address: 'Rua dos Andradas 1449',
        docId: '1111111111',
        birthDate: '14/09/1999',
        status: 'PENDING',
      },
    ],
  },
];

const SuggestionsScreen = () => {
  const { t: translate } = useTranslation();

  const [checked, setChecked] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);

  const [checkAll, setCheckAll] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');

  const profileIds = useMemo(() => {
    return suggestions?.map((s) => s?.id);
  }, []);

  const suggestionsIds = useMemo(() => {
    return suggestions.flatMap((s) => s.suggestions.map((sug) => sug.id));
  }, []);

  useEffect(() => {
    setIndeterminate(
      checked.filter((c) => !profileIds.includes(c)).length > 0 &&
        checked.filter((c) => !profileIds.includes(c)).length < suggestionsIds?.length
    );
    setCheckAll(
      checked.filter((c) => !profileIds.includes(c)).length === suggestionsIds?.length
    );
  }, [checked]);

  const onCheckAllChange = (e) => {
    const newChecked = e.target.checked ? suggestionsIds?.concat(profileIds) : [];
    setChecked(newChecked);
  };

  const selectAllSuggestions = (profileId) => {
    const sug = suggestions.find((s) => s?.id === profileId);
    if (sug.suggestions.every((s) => checked.includes(s?.id))) {
      setChecked(
        checked.filter(
          (c) => !sug.suggestions.some((s) => s?.id === c) && c !== profileId
        )
      );
    } else {
      setChecked([
        ...new Set(checked.concat(sug.suggestions?.map((s) => s.id)).concat([profileId])),
      ]);
    }
  };

  const openModalWithMessage = ({ type }) => {
    setModalType(type);
    setModalVisible(true);
  };

  return (
    <div id="global-profiles-list">
      <ConnectionModal
        buttonType={modalType}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
      <PageTitle
        title={translate(
          'pages.private.globalProfiles.index.connectExecutions',
          'Connect executions'
        )}
        subtitle={translate(
          'pages.private.globalProfiles.index.connectExecutionsSubtitle',
          'Connect the executions with the suggested profiles'
        )}
      />
      <Row className="no-mrg no-pdd" justify="space-between">
        <div className="flex-row start-center">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            style={{ marginLeft: 12, marginRight: 12 }}
          />{' '}
          <b>{translate('pages.private.globalProfiles.index.selectAll', 'Select all')}</b>
        </div>
        <div className="flex-row center">
          <Button
            type="link"
            onClick={() => openModalWithMessage({ type: 'not connect' })}
          >
            {translate('pages.private.globalProfiles.index.notConnect', 'Not connect')}
          </Button>
          <Button
            type="primary"
            className="mrg-left-10"
            onClick={() => openModalWithMessage({ type: 'connect' })}
          >
            {translate('pages.private.globalProfiles.index.connect', 'Connect')}
          </Button>
        </div>
      </Row>
      <Checkbox.Group
        style={{ width: '100%' }}
        value={checked}
        onChange={(checkedValues) => {
          const arrayDiff = _.difference(checked, checkedValues).concat(
            _.difference(checkedValues, checked)
          )[0];
          if (profileIds?.includes(arrayDiff)) {
            selectAllSuggestions(arrayDiff);
          } else {
            setChecked(checkedValues);
          }
        }}
      >
        {suggestions?.map((suggestion) => (
          <Collapse activeKey={suggestion.id} className="collapse-execs mrg-top-10">
            <Panel
              key={suggestion.id}
              collapsible="disabled"
              showArrow={false}
              header={
                <Row className="flex center">
                  <Col span={10}>
                    <Row className="no-mrg no-pdd">
                      <Checkbox
                        className="mrg-right-10"
                        value={suggestion?.id}
                        indeterminate={
                          ![0, suggestion?.suggestions.length].includes(
                            suggestion.suggestions.filter((s) => checked.includes(s?.id))
                              .length
                          )
                        }
                      />
                      <div className="flex-column">
                        <b className="text-default">
                          {translate(
                            'pages.private.globalProfiles.index.profile',
                            'Profile'
                          )}
                        </b>
                        <Link to="/" target="_blank" rel="noopener noreferrer">
                          Darlene Alves
                        </Link>
                      </div>
                    </Row>
                  </Col>
                  <Col span={3}>
                    <div className="flex-column">
                      <b className="text-default">
                        {translate('pages.private.globalProfiles.index.docId', 'Doc ID')}
                      </b>
                      <span>02588176070</span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className="flex-column">
                      <b className="text-default">
                        {translate(
                          'pages.private.globalProfiles.index.dateOfBirth',
                          'Date of birth'
                        )}
                      </b>
                      <span>14/09/1999</span>
                    </div>
                  </Col>
                  <Col span={8}>
                    <Row className="no-mrg no-pdd" justify="space-between">
                      <div className="flex-column">
                        <b className="text-default">
                          {translate(
                            'pages.private.globalProfiles.index.address',
                            'Address'
                          )}
                        </b>
                        <span>Rua dos Andradas</span>
                      </div>
                      <Button
                        type="link"
                        onClick={() => {
                          selectAllSuggestions(suggestion?.id);
                        }}
                      >
                        {translate(
                          'pages.private.globalProfiles.index.selectAll',
                          'Select all'
                        )}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <div style={{ paddingLeft: 29 }}>
                <b>
                  {translate(
                    'pages.private.globalProfiles.index.executions',
                    'Executions'
                  )}
                </b>

                {suggestion?.suggestions.map((exec) => (
                  <Collapse bordered={false}>
                    <Panel
                      // showArrow={false}
                      header={
                        <Row className="flex center">
                          <Col span={1}>
                            <Checkbox
                              value={exec?.id}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </Col>
                          <Col span={9}>
                            <Link
                              to="/"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="mrg-left-10"
                            >
                              Darlene Alves
                            </Link>
                          </Col>
                          <Col span={3} className="pdd-left-5">
                            <span>02588176070</span>
                          </Col>
                          <Col span={3} className="pdd-left-10">
                            <span>14/09/1999</span>
                          </Col>
                          <Col span={5}>
                            <span>Rua dos Andradas</span>
                          </Col>
                          <Col span={2} className="no-pdd">
                            <span>
                              <div className="card-info-status">
                                <div className="wrapper-icon-text">
                                  <IconStatus status="APPROVED" />
                                  <span
                                    style={{
                                      color: labelColor.APPROVED ?? '#2E70F0',
                                    }}
                                  >
                                    APPROVED
                                  </span>
                                </div>
                              </div>
                            </span>
                          </Col>
                          <Col span={1} justify="end">
                            <Close className="font-size-24" />
                          </Col>
                        </Row>
                      }
                    >
                      <Row>
                        <Col span={12}>
                          <Row className="row-info">
                            <Col span={12}>
                              <b>
                                {translate(
                                  'pages.private.globalProfiles.index.dateOfBirth',
                                  'Date of birth'
                                )}
                              </b>
                            </Col>
                            <Col span={12}>30-02-2000</Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className="row-info">
                            <Col span={12}>
                              <b>{translate('', 'Date of birth')}</b>
                            </Col>
                            <Col span={12}>30-02-2000</Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className="row-info">
                            <Col span={12}>
                              <b>{translate('', 'Date of birth')}</b>
                            </Col>
                            <Col span={12}>30-02-2000</Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className="row-info">
                            <Col span={12}>
                              <b>{translate('', 'Date of birth')}</b>
                            </Col>
                            <Col span={12}>30-02-2000</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Card className="mrg-top-10">
                            <ValidationsList
                              validations={[
                                {
                                  ruleName: 'Kyc sanctions latam countries found hits',
                                  ruleId: '6512f60597d0e53635f0683a',
                                  status: 'APPROVED',
                                },
                                {
                                  ruleName: 'Kyc peps latam countries found hits',
                                  ruleId: '6512f60597d0e53635f0683b',
                                  status: 'APPROVED',
                                },
                                {
                                  ruleName:
                                    'Kyc adverse media latam countries found hits',
                                  ruleId: '6530a88f23ef4b0d402f9cf9',
                                  status: 'APPROVED',
                                },
                                {
                                  ruleName: 'Kyc warnings latam countries found hits',
                                  ruleId: '655b6593fb1c953d25a7d216',
                                  status: 'APPROVED',
                                },
                              ]}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ))}
              </div>
            </Panel>
          </Collapse>
        ))}
      </Checkbox.Group>
    </div>
  );
};

export default SuggestionsScreen;
